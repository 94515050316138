<template>
  <div class="app-container">
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets" style="margin-top: 5px"></i>
      <span style="margin-top: 5px">Merchant Table</span>
      <el-button class="btn-add" @click="handleAddMerchantMenu()" size="mini">
        Add Menu
      </el-button>
    </el-card>
    <div class="table-container">
      <el-table
        ref="productCateTable"
        style="width: 100%"
        :data="list"
        v-loading="listLoading"
        border
      >
        <el-table-column label="Id" width="200" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="Name" align="center">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="Code" align="center">
          <template slot-scope="scope">{{ scope.row.code }}</template>
        </el-table-column>
        <el-table-column label="Type" align="center" width="200px">
          <template slot-scope="scope">{{ scope.row.type }}</template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleUpdateMerchantMenu(scope.row)"
              >编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDeleteMerchantMenu(scope.row.id)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes,prev, pager, next,jumper"
        :page-size="listQuery.pageSize"
        :page-sizes="[5, 10, 15]"
        :current-page.sync="listQuery.pageNum"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <el-form
        ref="productAttrCatForm"
        :model="merchantMenu"
        :rules="rules"
        label-width="120px"
      >
        <el-form-item label="Name(中文)" prop="localizedName.chs">
          <el-input v-model="merchantMenu.localizedName.chs" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="Name(English)" prop="localizedName.eng">
          <el-input v-model="merchantMenu.localizedName.eng" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="Menu Type" prop="type">
          <el-select
            v-model="merchantMenu.type"
            placeholder="Select Type"
          >
          <el-option v-for="item in typeOptions"
            :key="item"
            :value="item"
            :label="item">
          </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm('productAttrCatForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchListByMerchant,
  createMerchantMenu,
  deleteMerchantMenu,
  updateMerchantMenu,
} from '@/api/merchantMenu';

const typeOptions = [
  'PHYSICAL', 'DIGITAL',
];
export default {
  name: 'merchantList',
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 5,
      },
      dialogVisible: false,
      dialogTitle: '',
      updateId: null,
      merchantMenu: {
        localizedName: {
          chs: null,
          eng: null,
        },
        type: null,
      },
      typeOptions,
      rules: {
        name: [
          {
            required: true,
            message: 'Please input Menu Name',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: 'Please input Menu Code',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  watch: {
    $route(route) {
      this.getList();
    },
  },
  methods: {
    handleAddMerchantMenu() {
      this.dialogTitle = 'Add Merchant Menu';
      this.dialogVisible = true;
      this.merchantMenu.name = null;
      this.merchantMenu.type = null;
    },
    handleUpdateMerchantMenu(menu) {
      this.dialogTitle = 'Edit Merchant Menu';
      this.dialogVisible = true;
      this.updateId = menu.id;
      this.merchantMenu.name = menu.name;
      this.merchantMenu.type = menu.type;
    },
    getList() {
      this.listLoading = true;
      fetchListByMerchant(this.$route.query.id).then((response) => {
        this.listLoading = false;
        this.list = response.data;
      });
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getList();
    },
    handleDeleteMerchantMenu(id) {
      this.$confirm('Are you sure to delete this merchant?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteMerchantMenu(id).then((response) => {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 1000,
          });
          this.getList();
        });
      });
    },
    getMenuList() {
      this.$router.push();
    },
    handleConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.merchantMenu.merchantId = this.$route.query.id;
          if (this.dialogTitle === 'Add Merchant Menu') {
            createMerchantMenu(this.merchantMenu).then((response) => {
              this.$message({
                message: 'Success',
                type: 'success',
                duration: 1000,
              });
              this.dialogVisible = false;
              this.getList();
            });
          } else if (this.dialogTitle == 'Edit Merchant Menu') {
            updateMerchantMenu(this.updateId, this.merchantMenu).then(
              (response) => {
                this.$message({
                  message: 'Success',
                  type: 'success',
                  duration: 1000,
                });
                this.dialogVisible = false;
                this.getList();
              },
            );
          }
        } else {
          return false;
        }
      });
    },
  },
  filters: {},
};
</script>

<style scoped>
</style>
